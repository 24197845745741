@import './mixins';

html{
  scroll-behavior: smooth;
}

body{
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

h1,h2,h3,h4,h5{
  color: $header;
  letter-spacing: 0.5px;
}

p, span{
  color: $text;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: 0.3px;
  @include laptop{
    font-size: 14px;
    line-height: 160%;
  }
  @include mobile{
    font-size: 16px;
    line-height: 24px;
  }
}

a{
  text-decoration: none;
  color: $text;
  &:visited{
    color: $text
  }
}
.container{
  width: 90vw;
  margin-left: 10vw;
  position: absolute;
  padding: 5%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  display: flex;
  @include mobile{
    width: 100%;
    margin-left: 0%;
    z-index: 2;
    padding: 24px;
  }
}

button, a.button{
  background: transparent;
  padding: 12px 16px;
  cursor: pointer;
  outline: none;
  border: 1px solid;
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: $key1;
  border-color: $key1;
  transition: all 0.4s;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 400;
  &:active{
    opacity: 0.25;
  }
  &:after{
    content: '';
    width: 150%;
    height: 400%;
    border-radius: 50%;
    top: -200%;
    left: -25%;
    z-index: -1;
    position: absolute;
    background: $key1;
    transition: all 0.4s;
    transform: scale(0);
    opacity: 0;
  }
  &:hover{
    color: white;
  }
  &:hover::after{
    transform: scale(1);
    opacity: 1;
  }
  @include laptop{
    font-size: 12px;
  }
}
button.white{
  color: white;
  border-color: white;
  &:after{
      background: white;
  }
  &:hover{
      color: $key1
  }
}

.back-button{
  cursor: pointer!important;
  z-index: 5;
  width: 52px;
  height: 52px;
  background: $key3;
  border-radius: 50%;
  transition: all 0.2s;
  svg{
    fill: $key1;
  }
  &:hover{
    opacity: 0.5
  }
  @include laptop{
    width: 40px;
    height: 40px;
  }
  @include mobile{
    width: 36px;
    height: 36px;
  }
}

.video{
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: rgba($header, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  .video-close{
    cursor: pointer;
    width: 26px;
    height: 26px;
    position: absolute;
    right: 3.5%;
    top: 7.5%;
    transition: all 0.1s;
    &:hover{
      opacity: 0.4;
    }
    img{
      width: 100%;
    }
  }
  video{
        width: 70%; 
        z-index: 0;
        object-fit: cover;
        outline: none;
        object-position: center;
  }
}