@import './mixins';
.back-button{
    position: absolute;
    top: 5%;
    left: 5%;
    @include mobile{
        top: 24px;
        left: 24px;
    }
}
.projects{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0%;
    .left, .right, .right-project-view, .left-project-view{
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
    .left, .left-project-view{
        flex: 1;
        padding: 0% 5%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1{
            margin: 0%;
            text-transform: uppercase;
            color: $key1;
            font-size: 2rem;
            line-height: 2.8rem;
        }
        @include laptop{
            h1{
                font-size: 1.4rem;
            line-height: 2rem;
            }
        }
        img{
            width: 100%;
            margin-bottom: 16px;
        }
    }
    .left-project-view{
        position: relative;
    }
    .right, .right-project-view{
        flex: 2.5;
    }
      .right{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-sizing: border-box;
        .project-item{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            box-sizing: border-box;
            overflow: hidden;
            position: relative;
            width: 50%;
            height: 50%;
            .bg-image{
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.6;
                z-index: 0;
                transition: all 0.2s;
                filter: grayscale(100%) contrast(1) brightness(120%);
            }
            &::before{
                bottom: 0;
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width:  100%;
                z-index: 1;
                transition: all 0.2s;
                background-color: #3a6fd3;
                mix-blend-mode: hard-light;
            }
            cursor: pointer;
            h1{
                font-weight: 400;
                color: white;
                font-size: 1.15rem;
                text-transform: uppercase;
                letter-spacing: 0.15rem;
                margin: 7.5%;
                z-index: 2;
            }
            &:hover{
                &::before{
                    opacity: 0;
                }
                .bg-image{
                    filter: none;
                    opacity: 1;
                }
            }
        }
    }
    .right-project-view{
        background-position: center;
        background-size: cover;
    }
    @include mobile{
        flex-direction: column;
        padding-top: 80px;
        .left, .right, .right-project-view, .left-project-view{
            box-sizing: border-box;
            padding: 24px!important;
        }
        .left, .left-project-view{
            flex: unset;
            justify-content: flex-start;
            height: unset;
            h1{
                font-size: 28px;
                line-height: 36px;
            }
        }
        .right, .right-project-view{
            flex: 1;
            width: 100%;
        }
        .right{
            flex-direction: column;
            flex-wrap: unset;
            .project-item{
                width: 100%;
                height: 200px;
                margin-bottom: 16px;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.project-view-pagination{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10%;
    left: 0%;
    svg{
        cursor: pointer;
        width: 15px;
        height: 15px;
        fill: $key1;
        margin: 0% 5%;
        transition: all 0.2s;
        &:hover{
            opacity: 0.5;
        }
    }
    .circle{
        cursor: pointer;
        width: 12px;
        height: 12px;
        margin: 0% 5px;
        border-radius: 50%;
    }
    @include laptop{
        svg{
            width: 12px;
            height: 12px;
        }
        .circle{
          width: 9px;
          height: 9px;   
          margin: 0% 3px;
        }
    }
}