@import './mixins';

.blog{
    .left{
        width: 25%;
        height: 80%;
        position: fixed;
        margin: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
        .blog-featured-item{
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            box-sizing: border-box;
            padding: 7.5%;
            .blog-featured-content{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                background-color: rgba($key3, 0.925);
                box-sizing: border-box;
                padding: 7.5%;
                .author{
                    flex: 0.5;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .info{
                        display: flex;
                        flex-direction: column;
                        span{
                            font-size: 16px;
                            color: $key1;
                            font-weight: 400;
                            @include laptop{
                                font-size:14px;
                            }
                        }
                    }
                }
                .content{
                    flex: 5;
                    h1{
                        color: $key1;
                        font-size: 1.75rem;
                    }
                    p{
                        font-size: 1.15rem;
                    }
                    @include laptop{
                        h1{
                            font-size: 20px;
                            line-height: 132%;
                        }
                        p{
                            font-size: 14px;   
                        }
                    }
                }
                .action{
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    button{
                        font-size: 0.6rem;
                    }
                }
            }
        }
    }
    .right{
        margin-left: 37.5%;
        width: 62.5%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 4%;
        grid-row-gap: 4%;
        @include laptop{
            grid-column-gap: 20px;
            grid-row-gap: 20px;
        }
        .blog-item{
            cursor: pointer;
            transition: opacity 0.25s;
            height: 360px;
            display: flex;
            flex-direction: column;
            &:hover{
                opacity: 0.75;
            }
            .top{
                flex: 1;
                position: relative;
                display: flex;
                align-items: flex-end;
                box-sizing: border-box;
                padding: 1% 5%;
                h1{
                    color: white;
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    letter-spacing: 0.05rem;
                }
                .blog-image{
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    background-size: cover;
                    background-position: center;
                    &:before{
                        position: absolute;
                        z-index: 1;
                        content: '';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: rgba($key1, 0.75);
                    }
                }
                @include laptop{
                    h1{
                        font-size: 1rem;
                        line-height: 1.5rem;
                    }
                }
            }
            .bottom{
                flex: 1;
                display: flex;
                background: $key3;
                box-sizing: border-box;
                padding: 5%;
                flex-direction: column;
                justify-content: space-between;
                p{
                    font-size: 16px;
                    margin: 0%;
                    color: $text;
                }
                .blog-info{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    span{
                        font-size: 14px;
                        line-height: 14px;
                        flex: 1;
                        color: $key1;
                        text-align: right;
                    }
                }
                @include laptop{
                    p{
                        font-size: 14px;
                    }
                    .blog-info{
                        span{
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }
    @include mobile{
        display: flex;
        flex-direction: column;
        padding-top: 60px;
        h1{
            color: $key1;
        }
        .right{
            width: 100%;
            position: unset;
            margin: 0%;
            height: unset;
            display: unset;
            .blog-item{
                margin-bottom: 24px;
                .top{
                    h1{
                        font-size: 20px;
                    }
                }
                .bottom{
                    p{
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
                .blog-info{
                    span{
                        font-size: 14px!important;
                    }
                }
            }
        }
    }
}