@import './mixins';
.poiInfo, .poi{
    position: absolute;
    width: 100%;
}
.poiInfo{
    box-sizing: border-box;
    // padding: 5%;
    width: 100%;
    min-height: 100%;
    h1{
        color: $key1;
        margin-top: 7.5%;
    }
    img{
        max-width: 100%;
    }
    .poiInfo-left{
        display: inline-block;
        width: 39%;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        padding: 7.5% 5% 5% 5%;
    }
    .poiInfo-right{
        display: inline-flex;
        flex-direction: column;
        position: fixed;
        width: 45%;
        right: 0;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        border-left: $key3 solid 1px;
        
    }
    .poiInfo-content{
        margin-top: 5%;
    }
    .poiInfo-video{
        position: absolute;
        top: 0;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        width: 100%;
        height: 52%;
        background: $key3;
        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .poiInfo-modal{
        z-index: 10;
        position: fixed;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        background-color: rgba($header, 0.96);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        img{
            margin-left: -10%;
            width: 70%;
            height: 80%;
            object-fit: contain;
        }
    }
    .poiInfo-images{
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .image{
            width: 80%;
            height: 80%;
            margin-top: -2.5%;
            position: relative;
            img{
                cursor: pointer;
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
                transition: all 0.3s;
                &:hover{
                    cursor: zoom-in;
                }
            }
        }
        svg{
            cursor: pointer;
            width: 15px;
            height: 15px;
            fill: $key1;
            margin: -1.5% 2.5% 0% 2.5%;
            transition: all 0.2s;
            &:hover{
                opacity: 0.5;
            }
        }
        .imgCount{
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 7%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            div{
                cursor: pointer;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin: 0% 4px;
            }
        }
    }
    @include mobile{
        flex-direction: column;
        padding-top: 80px;
        .poiInfo-left,.poiInfo-right{
            box-sizing: border-box;
            width: 100%;
            padding: 24px;
            h1{
                margin-top: 0%;
                font-size: 28px;
            }
        }
        .poiInfo-modal{
            top: 0;
            left: 0
        }
        img{
            margin-left: 0%!important;
        }
        .poiInfo-video{
            height: 200px;
            margin-bottom: 24px;
        }
        .poiInfo-images{
            svg{
                margin: 0%!important;
            }
            position: relative;
            height: 240px;
            border: none;
        }
    }
}
.poi{
    height: 100%;
    .left{
        box-sizing: border-box;
        width: 61%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        padding: 5% 5% 3.5% 5%;
        display: flex;
        flex-direction: column;
        .poi-featured{
            box-sizing: border-box;
            padding-top: 7.5%;
            flex: 1;
            margin-bottom: 2.5%;
            position: relative;
            display: flex;
            flex-direction: row;
            .featured-content{
                flex: 1.25;
                margin-right: 5%;
                h1{
                    color: $key1;
                    font-size: 28px;
                    margin-top: 0%;
                }
                p{
                    font-size: 20px;
                }
            }
            .featured-image{
                flex: 1;
                height: 100%;
                background-repeat: no-repeat;
                background-position: top;
                background-size: contain;
            }
            button{
                position: absolute;
                left: 0%;
                bottom: 0%;
            }
            @include laptop{
                .featured-content{
                    h1{
                        font-size: 20px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
        .poi-list{
            flex: 1;
            margin-top: 5%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 3rem;
            grid-row-gap: 1rem;
            overflow-y: scroll;
            overflow-x: hidden;
            .poi-list-item{
                position: relative;
                height: 10rem;
                cursor: pointer;
                overflow: hidden;
                &:hover .poi-list-item-bg{
                    opacity: 0.6;
                }
            }
            .poi-list-item-bg, .poi-list-item-overlay{
                position: absolute;
                width: 100%;
                height: 100%;
            }
            .poi-list-item-bg{
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                transition: all 0.4s;
            }
            .poi-list-item-overlay{
                transition: all 0.2s;
                background: $key1;
            }
        }
    }
    @include mobile{
        display: flex;
        flex-direction: column;
        .left{
            width: 100%;
            position: unset;
            margin: 0%;
            height: unset;
            padding: 40px 0px 24px 0px;
            .back-button{
                position: fixed;
            }
            .poi-list{
                margin-top: 28vh;
                display: unset;
            }
            .poi-list-item{
                width: 100%;
                padding: 12px 24px;
                box-sizing: border-box;
                height: unset!important;
                align-items: flex-start;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .poi-list-item-info{
                    flex: 2.5;
                    margin-left: 16px;
                    h1{
                        margin-top: 0%;
                        margin-bottom: 0%;
                        color: $key1;
                        font-size: 16px;
                        line-height: 20px;
                    }
                    p{
                        margin-top: 8px;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
                .poi-list-item-bg{
                    background-position: 0% 0%;
                    position: unset!important;
                    flex: 1;
                    width: 100%;
                    height: 100px;
                }
            }
        }
    }
}