@import './mixins';

.districts{
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .overlay, .content{
        position: absolute;
        height: 100%;
    }
    .overlay{
        width: 100%;
        content: '';
        opacity: 0.8;
        z-index: 0;
        background: $key1;
    }
    .content{
        box-sizing: border-box;
        z-index: 1;
        padding: 10% 0%;
        display: flex;
        flex-direction: row;
        width: 70%;
        .right{
            flex: 1.5;
            padding-top: 12.5%;
        }
        .left{
            margin-right: 2.5%;
            flex: 1;
        }
        p, h1{
            margin: 0%;
        }
        p{
            color: white;
            font-size: 20px;
            line-height: 36px;
            font-weight: 300;
            letter-spacing: 0.5px;
        }
        h1{
            color: white;
            text-transform: uppercase;
            font-size: 4.75rem;
            line-height: 5.25rem;
            letter-spacing: 0.15rem;
            margin-left: -0.75%;
        }
        button{
            margin-top: 7.5%;
        }
    }
    @include mobile{
        min-height: 100vh;
        .content{
            padding: 52px 24px 24px 24px;
            flex-direction: column;
            width:  100%;
            box-sizing: border-box;
            .left, .right{
                flex: unset;
            }
            button{
                margin-top: 16px;
            }
            .right{
                padding-top: 24px;
            }
            h1{
                font-size: 36px;
                line-height: 44px
            }
            p{
                font-size: 15px;
                line-height: 23px;
            }
        }
    }
    @include laptop{
        .content{
            h1{
                font-size: 3.75rem;
                line-height: 4.75rem;
            }
            p{
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
}