@import './mixins';

.team{
    display: block;
    .left{
        width: 25%;
        position: fixed;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        margin: 0%;
        display: flex;
        flex-direction: column;
        h1,h3{
            margin-bottom: 3.5%;
        }
        h1{
            margin-top: -25%;
            color: $key1;
            font-size: 2rem;
        }
        h3{
            margin-top: 0%;
            font-size: 1.25rem;
            color: $text;
        }
        @include laptop{
            h1{
                font-size: 1.75rem;
            }
            h3{
                font-size: 1rem;
            }
        }
        justify-content: center;
    }
    .right{
        margin-left: 37.5%;
        width: 62.5%;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-sizing: border-box;
        justify-content: space-between;
        .member{
            width: 45%;
            &:nth-child(even){
                margin-top: 10%;
            }
            .member-photo{
                width: 100%;
                padding-bottom: 100%;
                background-position: center 15%;
                background-size: cover;
                position: relative;
                .member-photo-alt{
                    transition: opacity 0.15s;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-position: center 15%;
                    background-size: cover;
                    z-index: 5;
                    top: 0;
                    left: 0;
                }
            }
            .member-info-container{
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 5%;
                .member-info{
                    flex: 5;
                    p{
                        margin: 2.5% 0% 0% 0%;
                        text-transform: uppercase;
                        font-weight: 400;
                        @include laptop{
                            font-size: 13px;
                        }
                    }
                    .role{
                        color: rgba($text, 0.8);
                        letter-spacing: 0.015rem;
                    }
                    h1{
                        margin: 0%;
                        color: $key1;
                        font-size: 28px;
                        @include laptop{
                            font-size: 20px;
                        }
                    }
                }
                .member-links{
                    display: flex;
                    flex: 1;
                    flex-direction: row;
                    justify-content: flex-end;
                    a{
                        width: 24px;
                        height: 24px;
                        transition: opacity 0.25s;
                        margin-left: 10%;
                        cursor: pointer;
                        svg{
                            fill: $key1;
                        }
                        &:hover{
                            opacity: 0.5;
                        }
                        @include laptop{
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
    @include mobile{
        display: flex;
        flex-direction: column;
        .left, .right{
            width: 100%;
            position: unset;
            margin: 0%;
            height: unset;
        }
        .left{
            height: 360px;
            justify-content: flex-end;
            margin-bottom: 24px;
        }
        .right{
            padding-bottom: 52px;
            .member{
                width: 100%;
                margin-top: 28px !important;
                .member-info-container{
                    align-items: center;
                    flex-direction: column;
                    .member-info{
                        h1,p{
                            text-align: center;
                        }
                        h1{
                            font-size: 20px;
                        }
                        p{
                            font-size: 14px;
                            line-height: 24px;
                            margin-top: 4px;
                        }
                    }
                    .member-links{
                        justify-content: center;
                        width: 100%;
                        margin-top: 12px;
                        a{
                            margin:0%;
                            margin-right: 4px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
}