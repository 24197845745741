@import './mixins';

.home{
    width: 100%!important;
    margin: 0%!important;
    padding: 0%!important;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    background: $key1;
    .container{
        @include mobile{
            flex-direction: column;
        }
    }
    .content{
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        @include mobile{
            flex: 1;
            justify-content: flex-end;
            margin-bottom: 24px;
        }
    }
    .video_link{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        @include mobile{
            justify-content: flex-start;
            align-items: flex-start;   
        }
    }
    h1, p{
        color: white;
        width: 75%;
    }
    h1{
        font-size: 4.5rem;
        margin: 0;
        font-weight: 600;
    }
    p{
        font-size: 1.25rem;
        line-height: 2rem;
        letter-spacing: 0.05rem;
    }
    @include mobile{
        h1, p{
            width: unset;
        }
        h1{
            font-size: 40px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
    .overlay{
        width: 100%!important;
        height: 100%;
        position: fixed;
        background-color: $key1;
        z-index: -1;
        @include mobile{
            opacity: 0.6;
            z-index: 1;
        }
    }
    .home-vid{
        pointer-events: none;
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100vw; 
        z-index: 0;
        object-fit: cover;
        object-position: center;
        mix-blend-mode: soft-light;
        filter: grayscale(100%) contrast(80%);
        @include mobile{
            width: 100%;
            height: 100%;
            z-index: 0;
        }
    }
}

.video-container{
    width: 80%;
    height: 80%;
    position: relative;
    iframe{
        width: 100%;
        height: 100%;
        position: absolute;
    }
}