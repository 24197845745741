@import './mixins';

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}

.map{
    padding: 0%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    @include mobile{
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
    }
}

.google-map{ 
    width: 35%; 
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    @include mobile{
        top: 0;
        right: 0;
        width: 100%;
        height: 32vh;
    }
}