@import './mixins';

.menu{
    position: fixed;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 10vw;
    box-sizing: border-box;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 10;
    .links a{
        display: inline-block;
        text-decoration: none;
        text-transform: uppercase;
        margin-bottom: 1.8rem;
        font-size: 1rem;
        letter-spacing: 0.075rem;
        transition: all 0.15s;
        padding: 2.5px;
        font-weight: 500;
        position: relative;
        line-height: 1rem;
        &:hover:before{
            transform: scaleX(1)
        }
        &:before{
            content: '';
            width: 100%;
            top: 50%;
            height: 1px;
            position: absolute;
            transform: scaleX(0);
            transform-origin: 0%;
            transition: transform 0.3s;
        }
    }
    .links{
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        .active::before{
            transform: scaleX(1)
        }
    }
    .links_reg{
        color: $key1;
        &::before{
            background: $key1;
        }
    }
    .links_home{
        color: white;
        &::before{
            background: white;
        }
    }
    .icon{
        width: 2rem;
        height: 2rem;
        transition: all 0.2s;
        &:hover{
            opacity: 0.5;
        }
    }
    .icon_home path{
        fill: white
    }
    .icon_reg path{
        fill: $key1
    }
    .logo{
        width: 100%;
        img{
            width: 60%;
        }
    }
    @include mobile{
        transition: all 0.4s;
        right: -50vw;
        width: 50vw;
        background-color: rgba($key1, 0.95)!important;
        .logo{width: 72%}
        -webkit-box-shadow: 6px 0px 16px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 6px 0px 16px 0px rgba(0,0,0,0.1);
        box-shadow: 6px 0px 16px 0px rgba(0,0,0,0.1);
        .links a{
            color: white!important;
            &::before{
                background: white!important;
            }
        }
        .icon_reg path{
            fill: white!important;
        }
    }
    @include laptop{
        .logo img{
            width: 80%;
        }
        .links a{
            font-size: 0.8rem;
            margin-bottom: 20px;
        }
        .icon{
            width: 22px;
            height: 22px;
        }
    }
}

.menu-trigger{
    cursor: pointer;
    z-index: 10;
    width: 28px;
    height: 28px;
    top: 24px;
    right: 24px;
    position: fixed;
}

.menu-trigger-home{
    fill: white;
}
.menu-trigger-reg{
    fill: $key1;
}

.menu_reg{
    background-color: $key3;
}
.menu_home{
    background-color: rgba(white, 0.2);
}