$text: #6c6c75;
$header: #1c1c1c;
$bg: #ffffff;
$key1: #3A6FD3;
$key2: #dce2ea;
$key3: #f2f8ff;

@mixin mobile {
    @media only screen and (max-width: 1023px) {
      @content;
    }
}

@mixin laptop {
    @media (min-width: 1024px) and (max-width: 1550px){
        @content;
    }
}