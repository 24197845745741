@import './mixins';

.load-screen{
    z-index: 100;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    pointer-events: none;
    transition: all 0.3s;
    h1{
        color: $key1;
        margin: 0;
        font-weight: 300;
        font-size: 2.5em;
        letter-spacing: 0.25rem;
        opacity: 0;
        @include mobile{
            font-size: 28px;
            letter-spacing: 4px;
        }
    }
    h1.bold{
        font-weight: 500;
        color: $text;
    }
    @for $i from 1 to 11{
        h1:nth-child(#{$i}){
          animation: fadeIn 2s ease-in-out #{($i*0.1s)} infinite;
        }
    }
    @keyframes fadeIn {
        0%{
            opacity: 0;
            margin-bottom: -5%;
        }
        20%{
            opacity: 1;
            margin-bottom: 0%;
        }
        80%{
            opacity: 1;
            margin-bottom: 0%;
        }
        100%{
            opacity: 0;
            margin-bottom: -5%;
        }
    }
}

    