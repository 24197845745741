@import './mixins';

.about{
    display: flex;
    flex-direction: column;
    .about-header{
        width: 100%;
        height: 60vh;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    h1{
        z-index: 1;
        font-size: 1.75rem;
        letter-spacing: 0.025rem;
        text-align: center;
        color: $key1;
    }
    .about-content-row{
        margin: 5% 0%;
        -webkit-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 2%; 
        column-gap: 5%;
        h1{
            text-align: left;
            line-height: 2.25rem;
            margin: 0% 0% 5% 0%;
            color: $key1;
            font-size: 1.75rem;
        }
    }
    @include mobile{
        .about-header{
            height: 32vh;
            h1{
                color: white;
                z-index: 1;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
            }
            margin-top: 52px;
            margin-bottom: 16px;
            div{
                opacity: 0.5;
            }
            &:hover div{
                opacity: 0.5;
            }
        }
        .about-content-row{
            column-count: unset;
            h1{
                font-size: 28px;
                line-height: 32px;
            }
        }
    }
}